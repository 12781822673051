import APIController from '../core-api-controller';

export const fetchLeadsApi = (paramsObj = {}, paramsStr = '') => {
  const path = `${APIController.endpointApi}/leads${paramsStr ? paramsStr : ''}`;
  return APIController.getApiCallback(path, paramsObj);
};

export const fetchOrdersApi = (paramsObj = {}, paramsStr = '') => {
  const path = `${APIController.endpointApi}/orders${paramsStr ? paramsStr : ''}`;
  return APIController.getApiCallback(path, paramsObj);
};

export const mergeLeadsApi = (data, leadId = '') => {
  const path = `${APIController.endpointApi}/leads/${leadId}/merge_lead`;
  return APIController.postCallback(path, data);
};

export const syncLeadsApi = (data = {}) => {
  const path = `${APIController.endpointApi}/leads/sync`;
  return APIController.postCallback(path, data);
};
