import React, { Fragment, useEffect, useState } from 'react';
import cx from 'clsx';
import { Card } from '../../../common';
import DisplayDateTime from '../../../utils/time/display_time';
import styles from './LeadOrders.module.scss';
import StatusButton from '../../../utils/status/status_button';
import { isEmptyArray } from '../../../utils';
import { Flex, Row, Col } from 'antd';

const LeadOrders = ({ ordersData = [], rootClassName = '' }) => {
  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    if (ordersData?.length > 0) {
      setSelectedOrder(ordersData[0]);
    }
  }, [ordersData?.length]);

  const isActiveCard = (id) => id === selectedOrder?.id;

  if (isEmptyArray(ordersData)) return null;

  return (
    <Fragment>
      <div className={cx('col-8', rootClassName)}>
        <Card className={styles.rootOrdersContainer}>
          <div className="mb-2 card-title">Samcart Orders</div>
          <Card className={cx(styles.scrollContainer)} bodyStyle={{ padding: '8px' }}>
            {ordersData?.map((orderItem, orderIndex) => (
              <div key={orderIndex}>
                <Card
                  key={orderItem?.id}
                  className={cx({ ['mb-4 hover']: true, [styles.activeCard]: isActiveCard(orderItem?.id) })}
                  bordered
                  hoverable
                  onClick={() => setSelectedOrder(orderItem)}
                >
                  <Flex align="items-center" justify="space-between">
                    <Flex>
                      <div className="mb-3 text-muted">Order #</div>
                      <div className="mb-3 ms-2">{orderItem?.samcart_order_id}</div>
                    </Flex>
                    <div>
                      <StatusButton status={orderItem?.status} />
                    </div>
                  </Flex>
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div className="mb-2 ms-2 text-muted">Product</div>
                      <div className="mb-3 ms-3">{orderItem?.product?.name}</div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="mb-2 ms-2 text-muted">Order Date</div>
                      <div className="mb-3 ms-3">{DisplayDateTime(orderItem?.order_date)}</div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="mb-2 ms-2 text-muted">Total</div>
                      <div className="mb-3 ms-3">{orderItem?.total}</div>
                    </div>
                    {orderItem?.refund && (
                      <div className="col-md-6 col-12">
                        <div className="mb-2 ms-2 text-muted">Refund</div>
                        <div className="mb-3 ms-3">{orderItem?.refund}</div>
                      </div>
                    )}
                    {orderItem?.subscription && (
                      <Fragment>
                        <div className="col-md-6 col-12">
                          <div className="mb-2 ms-2 text-muted">Initial Price</div>
                          <div className="mb-3 ms-3">{orderItem.subscription?.initial_price ?? '-'}</div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="mb-2 ms-2 text-muted">Recurring Price</div>
                          <div className="mb-3 ms-3">{orderItem.subscription?.recurring_price ?? '-'}</div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="mb-2 ms-2 text-muted">Start Date</div>
                          <div className="mb-3 ms-3">{DisplayDateTime(orderItem.subscription?.start_date)}</div>
                        </div>
                        <div className="col-md-6 col-12">
                          <div className="mb-2 ms-2 text-muted">Next Rebilling Date</div>
                          <div className="mb-3 ms-3">
                            {DisplayDateTime(orderItem.subscription?.next_rebilling_date)}
                          </div>
                        </div>
                        {orderItem.subscription?.end_date && (
                          <div className="col-md-6 col-12">
                            <div className="mb-2 ms-2 text-muted">End Date</div>
                            <div className="mb-3 ms-3">{DisplayDateTime(orderItem.subscription?.end_date) ?? '-'}</div>
                          </div>
                        )}
                      </Fragment>
                    )}
                  </div>
                </Card>
              </div>
            ))}
          </Card>
        </Card>
      </div>
      <div className="col-4 ps-2">
        <Card className={styles.rootOrdersContainer}>
          <div className="mb-2 card-title">Order Charges</div>
          <Card className={styles.scrollContainer} bodyStyle={{ padding: '8px' }}>
            {selectedOrder &&
              !isEmptyArray(selectedOrder?.charges) &&
              selectedOrder.charges?.map((charge) => (
                <Card className={cx('mb-4', styles.activeCard)} key={charge.id} bordered>
                  <Flex vertical gap={35}>
                    <Flex justify="space-between">
                      <div className="d-flex align-items-center">
                        <div className={cx('text-muted')}>ID#</div>
                        <div className="ms-2">{charge?.samcart_charge_id ?? '-'}</div>
                      </div>
                      <div>
                        <StatusButton status={charge?.status} />
                      </div>
                    </Flex>
                    <Row gutter={[10, 30]}>
                      <Col span={12}>
                        <div className={cx(styles.chargesCardHeading, 'text-muted')}>Total</div>
                        <div>{charge?.total ?? '-'}</div>
                      </Col>
                      <Col span={12}>
                        <div className={cx(styles.chargesCardHeading, 'text-muted')}>Charged At</div>
                        <div>{DisplayDateTime(charge?.charge_at) ?? '-'}</div>
                      </Col>
                    </Row>
                  </Flex>
                </Card>
              ))}
          </Card>
        </Card>
      </div>
    </Fragment>
  );
};

export default LeadOrders;
