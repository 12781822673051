import { Flex, Row, Col } from 'antd';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import RenderFilters from './render-filters/RenderFilters';
import { convertQueryObjToString, mapQueryStringToFilterObject } from '../../utils/filters-util';
import { isEmptyObject } from '../../utils';

const Filters = ({ filtersList = [], fetch = () => {}, rootPathUrl = '', loading = false }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [filtersObject, setFiltersObject] = useState({});

  const fetchData = () => {
    const queryObj = mapQueryStringToFilterObject(location.search || '');
    const updatedFilterObject = { ...queryObj?.queryObj };
    setFiltersObject(updatedFilterObject);
    isEmptyObject(updatedFilterObject) ? fetch() : fetch(updatedFilterObject);
  };

  const handleFiltersChange = (updatedFilterObject) => {
    navigate({
      pathname: location.pathname,
      search: convertQueryObjToString(updatedFilterObject),
    });
  };

  useEffect(() => {
    fetchData();
  }, [location.search]);

  return (
    <Flex gap={8} className="mb-3">
      <Row gutter={[16, 16]} className="d-flex w-100" wrap>
        {filtersList?.length &&
          filtersList.map((item, index) => (
            <Col xs={24} sm={12} md={8} lg={6} xl={6} key={index}>
              <RenderFilters
                item={item}
                filtersObject={filtersObject}
                setFiltersObject={setFiltersObject}
                loading={loading}
                handleFiltersChange={handleFiltersChange}
              />
            </Col>
          ))}
      </Row>
    </Flex>
  );
};

export default Filters;
