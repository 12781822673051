import React from 'react';
import { Modal as AntdModal } from 'antd';
import styles from './Modal.module.scss';

const Modal = ({
  open,
  handleCancel = () => {},
  handleOk = () => {},
  ModalBody,
  title,
  width = 1000,
  modalStyles,
  showActions = false,
  okText = 'Submit',
  cancelText = 'Cancel',
  closable = true,
  showCustomFooter = false,
  customFooter = [],
  destroyOnClose = false,
  bodyAsFunction = false,
  okButtonProps = {},
  maskClosable = true,
}) => {
  return (
    <AntdModal
      title={title}
      open={open}
      onOk={handleOk}
      closable={closable}
      centered
      maskClosable={maskClosable}
      width={width}
      onCancel={(event) => {
        event.stopPropagation();
        handleCancel();
      }}
      {...(!showActions && !showCustomFooter
        ? { footer: null }
        : showCustomFooter && !showActions && { footer: customFooter })}
      style={modalStyles}
      okText={okText}
      cancelText={cancelText}
      destroyOnClose={destroyOnClose}
      okButtonProps={{ ...okButtonProps, size: 'default' }}
      cancelButtonProps={{ size: 'default' }}
      rootClassName={styles.modalWrap}
    >
      {bodyAsFunction ? <ModalBody /> : ModalBody}
    </AntdModal>
  );
};

export default Modal;
