import { createSlice } from '@reduxjs/toolkit';
import { PURGE } from 'redux-persist';

const initialState = {
  data: {},
  loading: false,
};

const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.data = action?.payload;
    },
    removeUser: (state, action) => {
      state.data = {};
    },
  },
  extraReducers(builder) {
    builder.addCase(PURGE, (state) => {
      state = { ...initialState };
    });
  },
});

export const { setUser, removeUser } = UserSlice.actions;
export default UserSlice.reducer;
