import React from 'react';
import { Card as AntdCard } from 'antd';

const Card = ({ headerTitle = null, children, className, footer, bordered = false, Extra = null, ...rest }) => (
  <AntdCard
    title={headerTitle}
    className={className}
    bordered={bordered}
    extra={Extra}
    {...rest}
    // style={{ boxShadow: 'none' }}
  >
    {children && children}
    {footer && (
      <div id="card-footer" style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        {footer}
      </div>
    )}
  </AntdCard>
);

export default Card;
