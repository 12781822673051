import React from 'react';
import DataTable from 'react-data-table-component';
import CustomLoader from '../../utils/data_table_loader/custom_loader';
import { pageLimitOptions } from '../../constants/generic-lists';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { convertQueryObjToString, mapQueryStringToFilterObject } from '../../utils/filters-util';

const DataTableComponent = ({
  columns = [],
  tableData = [],
  progressPending = false,
  paginationObject = {},
  ...rest
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { count: totalRecords, items, page: currentPage, pages: totalPages } = paginationObject || {};
  let limitPerPage = searchParams?.get('per_page') || process.env.REACT_APP_DEFAULT_PAGE_SIZE;
  const defaultPageProp = currentPage ? { paginationDefaultPage: currentPage } : {};

  const handleFiltersChange = (updatedFilterObject) => {
    navigate({
      pathname: location.pathname,
      search: convertQueryObjToString(updatedFilterObject),
    });
  };

  const onChangePage = (page) => {
    if (currentPage !== page) {
      const queryObj = mapQueryStringToFilterObject(location.search || '');
      const updatedFilterObject = { ...queryObj?.queryObj, page: page };
      handleFiltersChange(updatedFilterObject);
    }
  };

  const onChangeRowsPerPage = (newPerPage, page) => {
    const queryObj = mapQueryStringToFilterObject(location.search || '');
    const updatedFilterObject = { ...queryObj?.queryObj, per_page: newPerPage };
    handleFiltersChange(updatedFilterObject);
  };

  const onSortColumn = (column, sortDirection) => {
    const queryObj = mapQueryStringToFilterObject(location.search || '');
    const updatedFilterObject = { ...queryObj?.queryObj, 'q[s]': `${column.sortField}%20${sortDirection}` };
    handleFiltersChange(updatedFilterObject);
  };

  return (
    <div className="table-wrapper">
      <DataTable
        //data
        columns={columns}
        data={tableData}
        //style
        dense={true}
        direction="auto"
        highlightOnHover
        noHeaderpagination
        persistTableHead
        pointerOnHover
        responsive
        subHeaderAlign="right"
        subHeaderWrap
        progressPending={progressPending}
        progressComponent={<CustomLoader />}
        //pagination
        pagination
        paginationServer
        paginationPerPage={limitPerPage}
        paginationTotalRows={totalRecords}
        paginationRowsPerPageOptions={pageLimitOptions}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        {...defaultPageProp}
        sortServer={true}
        onSort={onSortColumn}
        {...rest}
      />
    </div>
  );
};

export default DataTableComponent;
