import './dashboard.css';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import SideBarContent from './admin_left_sidebar';
import ProfileDropdown from '../profile_drop_down';
import logo from '../../assets/logos/logo_round.png';
import { getCookie } from '../../utils/cookies-util';
import { Layout } from 'antd';
import SideMenu from '../side-menu/SideMenu';
const { Content } = Layout;

const ProfileMenus = [
  {
    label: 'Logout',
    icon: 'log-out',
    redirectTo: '/',
  },
];

const AdminDashboardLayout = ({ children }) => {
  const [sidebar, setSidebar] = useState(true);
  const scrollRef = useRef(null);
  const showSidebar = () => {
    setSidebar(!sidebar);
    // Toggle class on content-page when sidebar state changes
    const contentPage = document.querySelector('.content-page');
    if (contentPage) {
      contentPage.classList.toggle('collapsed-sidebar', sidebar);
    }
  };
  return (
    <Layout>
      <Layout>
        {/* <div className={`left-side-menu front  ${sidebar ? 'activeDash' : 'hideSideBar'}`}>
          <SideBarContent username={getCookie('userType')} />
        </div> */}
        <SideMenu />

        <Layout
          style={{
            // marginInlineStart: 72,
            // marginBlockStart: 'var(--ant-layout-header-height)',
            overflowX: 'hidden',
          }}
        >
          <Content className="main-content" ref={scrollRef}>
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
export default AdminDashboardLayout;
