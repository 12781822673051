export default function DisplayDateTime(TimeStamp) {
  if (!TimeStamp) {
    return null;
  }

  let date = new Date(TimeStamp);
  let currentDate = new Date();
  let timeDifference = Math.abs(currentDate.getTime() - date.getTime());

  if (timeDifference < 60 * 1000) {
    //   // Within a minute
    return 'now';
    //  }else if (timeDifference < 60 * 60 * 1000) {
    //   // Within an hour
    //   let minDifference = Math.floor(timeDifference / (1000 * 60));
    //   return `${minDifference} min ago`;
  } else {
    // Display date and time
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  }
}
