import React from 'react';
import cx from 'clsx';
import { Card } from 'react-bootstrap';
import Timeline from '../../common/timeline/TimeLine';
import { isEmptyArray } from '../../utils';
import styles from './RecentActivity.module.scss';
import DisplayDateTime from '../../utils/time/display_time';

const RecentActivity = ({ name = '', data = [] }) => {
  const RowComponent = ({ data = {} }) => {
    return (
      <div className={cx('d-flex flex-row justify-content-between', styles.ActivityRowText)}>
        <div>{data?.name || ''}</div>
        <div>{DisplayDateTime(data?.timestamp)}</div>
      </div>
    );
  };

  return (
    <div className="mb-4">
      {!isEmptyArray(data) && (
        <>
          <Card.Subtitle className={cx('mb-2 ms-1 ', styles.headingText)}>{name}</Card.Subtitle>
          <Timeline timeLineData={data} TimeLineBodyComponent={RowComponent} />
        </>
      )}
    </div>
  );
};

export default RecentActivity;
