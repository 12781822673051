import React, { Fragment, useState, useRef } from 'react';
import { Button, Modal } from '../../../common';
import MergeLeadModalBody from './MergeLeadModalBody';
import { mergeLeadsApi } from '../../../api/leads-api';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { getErrorString } from '../../../utils';
import { LEAD_DETAIL_PATH } from '../../../constants/admin-paths';

const MergeLeadModal = ({ leadId = '' }) => {
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const formRef = useRef();

  const handleSubmit = async (values) => {
    if (!values?.email || !leadId) return;
    const payload = {
      email: values?.email,
    };
    try {
      setLoading(true);
      const res = await mergeLeadsApi(payload, leadId);
      if (res && res?.status) {
        navigate(LEAD_DETAIL_PATH.replace(':id', res?.lead_id), { replace: true });
        toast.success('Lead Merged Successfully');
        setModalOpen(false);
      } else {
        toast.error(res?.message || 'Error Merging Lead');
      }
    } catch (error) {
      toast.error(getErrorString(error));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Button text="Merge Lead" size="medium" type="primary" onClick={() => setModalOpen(true)} />

      <Modal
        title={'Merge Lead'}
        open={modalOpen}
        ModalBody={<MergeLeadModalBody handleSubmit={handleSubmit} ref={formRef} />}
        handleCancel={() => setModalOpen(false)}
        handleOk={() => {
          formRef?.current?.submitForm();
        }}
        okButtonProps={{ loading }}
        okText="Confirm Merge"
        showActions={true}
        destroyOnClose={true}
        width={500}
      />
    </Fragment>
  );
};

export default MergeLeadModal;
