import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { getErrorString } from '../../utils';
import { fetchLeadsApi } from '../../api/leads-api';

const initialState = {
  leads: [],
  caseStatuses: [],
  productStatuses: [],
  leadStatuses: [],
  loading: true,
  pending: false,
  pagination: {},
};

export const fetchLeadsListing = createAsyncThunk('leads/listing', async (updatedParamsObject = {}) => {
  try {
    const paramsObj = {
      'q[s]': 'updated_at desc',
      ...updatedParamsObject,
    };
    const res = await fetchLeadsApi(paramsObj);
    return res;
  } catch (error) {
    toast.error(getErrorString(error));
  }
});

const LeadsSlice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    setLeadsLoading: (state, action) => {
      state.loading = action?.payload?.loading;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLeadsListing.pending, (state) => {
        state.pending = true;
      })
      .addCase(fetchLeadsListing.fulfilled, (state, action) => {
        state.leads = action?.payload?.data || [];
        state.caseStatuses = action?.payload?.case_statuses || [];
        state.productStatuses = action?.payload?.products || [];
        state.leadStatuses = action?.payload?.lead_statuses || [];
        state.loading = false;
        state.pending = false;
        state.pagination = action?.payload?.meta?.pagination || {};
      })
      .addCase(fetchLeadsListing.rejected, (state) => {
        state.loading = false;
        state.pending = false;
      });
  },
});

export const { setLeadsLoading } = LeadsSlice.actions;
export default LeadsSlice.reducer;
