import React from 'react';
import cx from 'clsx';
import RenderIcon from './render-icon/RenderIcon';
import styles from './Icon.module.scss';

const Icon = ({
  icon,
  size,
  bgSize,
  iconSize = '1.2em',
  color,
  bgColor,
  hasBackground,
  containerClassName,
  ...rest
}) => {
  return hasBackground ? (
    <div
      className={cx(styles.hasBackground, containerClassName)}
      style={{
        '--icon-bg': bgColor,
        '--icon-color': color,
        '--icon-bg-size': bgSize,
        ...rest.bgStyle,
      }}
    >
      <RenderIcon icon={icon} iconSize={iconSize} color={color} {...rest} />
    </div>
  ) : (
    <RenderIcon icon={icon} iconSize={iconSize} color={color} {...rest} />
  );
};

export default Icon;
