import { Cookies } from 'react-cookie';

export const getCookie = (name) => {
  const cookie = new Cookies();
  const value = cookie.get(name);
  return value || '';
};

export const setCookie = (name, value, options = { path: '/' }) => {
  const cookie = new Cookies();
  cookie.set(name, value, options);
};

export const removeCookie = (name, options = { path: '/' }) => {
  const cookie = new Cookies();
  cookie.remove(name, options);
  cookie.remove(name, { ...options, path: '/admin' });
};

export const removeAppCookies = () => {
  removeCookie('access-token');
  removeCookie('user-type');
};
