import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Modal } from '../../../common';
import { USERS_MODAL_TYPES } from '../../../constants/generic-constants';
import UserInfoModalBody from './UserInfoModalBody';

const UserInfoModal = ({
  toggle = false,
  modalType = USERS_MODAL_TYPES.ADD_USER,
  userRoles = [],
  isEdit = false,
  userId = '',
  userData = {},
  setUserModalData = () => {},
  onSuccess = () => {},
}) => {
  const [modalData, setModalData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const formRef = useRef(null);

  const toggleModal = ({ toggle = false }) => {
    setUserModalData((prev) => {
      return { ...prev, toggle: toggle };
    });
  };

  const getModalData = ({ type, toggle = false }) => {
    switch (type) {
      case USERS_MODAL_TYPES.ADD_USER:
        setModalData({
          open: toggle,
          title: 'Add User',
          Body: (
            <UserInfoModalBody
              ref={formRef}
              setIsLoading={setIsLoading}
              userRoles={userRoles}
              onSuccess={() => {
                toggleModal({ toggle: false });
                onSuccess();
              }}
            />
          ),
          handleOK: () => {
            formRef?.current?.submitForm();
          },
          handleCancel: () => {
            toggleModal({ toggle: false });
          },
          okText: 'Add',
          width: 1000,
          showActions: true,
        });
        break;
      case USERS_MODAL_TYPES.EDIT_USER:
        setModalData({
          open: toggle,
          title: 'Edit User',
          Body: (
            <UserInfoModalBody
              ref={formRef}
              setModalOpen={toggleModal}
              userId={userId}
              userData={userData}
              setIsLoading={setIsLoading}
              userRoles={userRoles}
              onSuccess={() => {
                toggleModal({ toggle: false });
                onSuccess();
              }}
            />
          ),
          handleOK: () => {
            formRef?.current?.submitForm();
          },
          handleCancel: () => {
            toggleModal({ toggle: false });
          },
          okText: 'Update',
          width: 1000,
          showActions: true,
        });
        break;
      default:
        return;
    }
  };

  useEffect(() => {
    getModalData({ type: modalType, toggle: toggle });
  }, [modalType, toggle]);

  return (
    <Fragment>
      <Modal
        title={modalData?.title}
        open={modalData?.open}
        ModalBody={modalData?.Body}
        handleOk={modalData?.handleOK}
        handleCancel={modalData?.handleCancel}
        width={modalData?.width}
        showActions={modalData?.showActions}
        okText={modalData?.okText}
        modalStyles={modalData?.modalStyles}
        okButtonProps={{ loading: isLoading }}
        destroyOnClose
      />
    </Fragment>
  );
};

export default UserInfoModal;
