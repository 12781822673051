export const filterFieldFormat = (string) => {
  return string?.replaceAll('%20', ' ');
};

export const formatOptionsForSelect = ({ list = [], setAsSlug = false }) => {
  return list?.map((item) => {
    return {
      ...item,
      value: item?.id,
      label: item?.name ?? item?.title ?? item?.value,
      name: item?.name ?? item?.title ?? item?.value,
      slug: setAsSlug ? item?.slug : item?.id,
    };
  });
};

export const formatValueForSelect = ({ obj = null, key = 'name' }) => {
  return obj ? { ...obj, label: obj?.[key] } : null;
};

export const mapQueryStringToFilterObject = (search, cb = () => {}, setFilterChips) => {
  const queryObj = {},
    queryString = search?.split('?')[1];
  if (queryString) {
    const items = queryString.split('&');
    for (let item of items) {
      if (item.split('=')[0] !== 'widget_for') {
        const key = item.split('=')[0],
          value = item.split('=')[1];
        cb(key, filterFieldFormat(value));
        if (key && value) {
          if (queryObj[key]) {
            queryObj[key] += ',' + filterFieldFormat(value);
          } else {
            queryObj[key] = filterFieldFormat(value);
          }
        }
      }
    }
  } else {
    !!setFilterChips && setFilterChips(null);
  }
  return { queryObj };
};

export const convertQueryObjToString = (obj, encode = false) => {
  let keys = Object.keys(obj);
  if (keys?.length) {
    return keys
      .map((key) =>
        typeof obj[key] !== 'number' && obj[key]?.indexOf(',') !== -1
          ? obj[key]
              ?.split(',')
              .map((e) => key + '=' + e)
              .join('&')
          : obj[key]
            ? key + '=' + (encode ? encodeURI(obj[key]) : obj[key])
            : '',
      )
      ?.filter((item) => !!item)
      ?.join('&');
  } else {
    return '';
  }
};

export const getQueryString = (filters, noStart = false) => {
  let queryString = '';
  if (filters) {
    queryString = `${noStart ? '' : '?'}` + convertQueryObjToString(filters, true);
  }
  return queryString;
};
