import React from 'react';
import { Input as AntdInput } from 'antd';
import Label from '../label/Label';
import ErrorMessage from '../error-message/ErrorMessage';

const Input = ({
  containerClassName = '',
  value = '',
  placeholder = '',
  label = '',
  errorMsg = '',
  size = 'large',
  name = '',
  type = 'text',
  onChange = () => {},
  onBlur = () => {},
  onPressEnter = () => {},
  allowClear = false,
  autoFocus = false,
  required = false,
  labelClassName = '',
  disabled = false,
  inputRef,
  isPassword = false,
  ...rest
}) => {
  return (
    <div className={containerClassName}>
      {label && (
        <Label className={labelClassName} required={required}>
          {label}
        </Label>
      )}
      {isPassword ? (
        <AntdInput.Password
          value={value}
          placeholder={placeholder}
          size={size}
          name={name}
          type={type}
          status={errorMsg && 'error'}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          onPressEnter={onPressEnter}
          allowClear={allowClear}
          autoFocus={autoFocus}
          ref={inputRef}
          {...rest}
        />
      ) : (
        <AntdInput
          value={value}
          placeholder={placeholder}
          size={size}
          name={name}
          type={type}
          status={errorMsg && 'error'}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          onPressEnter={onPressEnter}
          allowClear={allowClear}
          autoFocus={autoFocus}
          ref={inputRef}
          {...rest}
        />
      )}
      {errorMsg && <ErrorMessage errorMessage={errorMsg} />}
    </div>
  );
};

export default Input;
