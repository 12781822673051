import React from 'react';
import cx from 'clsx';
import styles from './Label.module.scss';

const Label = ({ children, required, className, size, ...rest }) => {
  const setClass = (size) => {
    switch (size) {
      case size:
        return styles[size];
      default:
        return '';
    }
  };

  return (
    <label className={cx('subtitle-2', styles.label, className, setClass(size))} {...rest}>
      {children} {required && <sup>*</sup>}
    </label>
  );
};

export default Label;
