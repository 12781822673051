import React from 'react';
import RecentActivity from '../../recent_activity/RecentActivity';
import { Card } from '../../../common';
import styles from './LeadRecentActivities.module.scss';

const LeadRecentActivities = ({ leadData = {} }) => {
  return (
    <Card className={styles.mainContainer}>
      <div className="mb-3 card-title">Recent Activity</div>
      <div className={styles.activitiesContainer}>
        {leadData?.previous_statuses && <RecentActivity name={'Lead'} data={leadData?.previous_statuses ?? []} />}
        {leadData?.order && <RecentActivity name={'Order'} data={leadData?.order?.previous_statuses ?? []} />}
        {leadData?.cases?.length > 0 && leadData?.cases[0] && (
          <RecentActivity name={'Case'} data={leadData?.case?.previous_statuses ?? []} />
        )}
        {leadData?.cases?.length > 0 && leadData?.cases[0] && (
          <RecentActivity name={'Case Notifications from Mdi'} data={leadData?.cases[0]?.mdi_notifications ?? []} />
        )}
      </div>
    </Card>
  );
};

export default LeadRecentActivities;
