import React, { Fragment, useEffect, useState } from 'react';
import cx from 'clsx';
import { Card } from '../../../common';
import { isEmptyArray } from '../../../utils';
import StatusButton from '../../../utils/status/status_button';
import DisplayDateTime from '../../../utils/time/display_time';
import styles from './LeadCases.module.scss';
import { Col, Flex, Row } from 'antd';

const LeadCases = ({ casesData = [], rootClassName = '' }) => {
  const [selectedCase, setSelectedCase] = useState(null);

  const isActiveCard = (id) => id === selectedCase?.id;

  useEffect(() => {
    if (casesData?.length > 0) {
      setSelectedCase(casesData[0]);
    }
  }, [casesData?.length]);

  if (isEmptyArray(casesData)) return null;

  return (
    <Fragment>
      <div className={cx('col-8', rootClassName)}>
        <Card className={styles.rootCaseContainer}>
          <div className="mb-2 card-title">Cases</div>
          <Card className={styles.scrollContainer} bodyStyle={{ padding: '8px' }}>
            {casesData?.map((caseItem) => (
              <Card
                className={cx({ ['mb-4 hover']: true, [styles.activeCard]: isActiveCard(caseItem?.id) })}
                key={caseItem?.id}
                hoverable
                bordered
                onClick={() => setSelectedCase(caseItem)}
              >
                <Flex align="center" justify="space-between">
                  <Flex className={cx({ [styles.activeText]: isActiveCard(caseItem?.id) })}>
                    <div className={cx('ms-2')}>Mdi Case Id</div>
                    <div className="ms-3">{caseItem.mdi_case_id}</div>
                  </Flex>
                  <div>
                    <StatusButton status={caseItem.status} />
                  </div>
                </Flex>
                <div className="mb-3 text-muted"></div>
                <div className="row">
                  <div className="col-md-6 col-12">
                    <div className="mb-2 ms-2 text-muted">Clinician</div>
                    <div className="mb-3 ms-3">{caseItem.clinician?.full_name ?? '-'}</div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="mb-2 ms-2 text-muted">created at</div>
                    <div className="mb-3 ms-3">{DisplayDateTime(caseItem.mdi_created_at)}</div>
                  </div>
                  {caseItem.tag_names.length > 0 && (
                    <div className="col-md-6 col-12">
                      <div className="mb-2 ms-2 text-muted">tags</div>
                      <div className="mb-3 ms-3">
                        {caseItem.tag_names.map((item, index) => (
                          <Fragment key={index}>
                            {item}
                            <br />
                          </Fragment>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </Card>
            ))}
          </Card>
        </Card>
      </div>
      <div className="col-4 ps-2">
        <Card className={styles.rootCaseContainer}>
          <div className="mb-2 card-title">Case Prescription</div>
          <Card className={styles.scrollContainer} bodyStyle={{ padding: '8px' }}>
            {selectedCase &&
              !isEmptyArray(selectedCase?.prescriptions) &&
              selectedCase.prescriptions.map((prescription) => (
                <Card className={cx('mb-4', styles.activeCard)} key={prescription.id} bordered>
                  <Flex vertical gap={35}>
                    <Flex vertical>
                      <div className={cx(styles.prescriptionCardHeading, 'text-muted')}>Name</div>
                      <div>{prescription?.name}</div>
                    </Flex>
                    <Flex vertical>
                      <div className={cx(styles.prescriptionCardHeading, 'text-muted')}>Directions</div>
                      <div>{prescription?.directions}</div>
                    </Flex>
                    <Row gutter={[10, 30]}>
                      <Col span={12}>
                        <div className={cx(styles.prescriptionCardHeading, 'text-muted')}>Days Supply</div>
                        <div>{prescription?.days_supply}</div>
                      </Col>
                      <Col span={12}>
                        <div className={cx(styles.prescriptionCardHeading, 'text-muted')}>Pharmacy</div>
                        <div>{prescription?.pharmacy_name}</div>
                      </Col>
                    </Row>
                  </Flex>
                </Card>
              ))}
          </Card>
        </Card>
      </div>
    </Fragment>
  );
};

export default LeadCases;
