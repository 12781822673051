import React from 'react';
import { Navigate } from 'react-router-dom';
import { getCookie } from '../../utils/cookies-util';

const ProtectedRoute = ({ user, children }) => {
  const validToken = getCookie('access-token');
  return user && validToken ? children : <Navigate to="/signin" replace={true} />;
};

export default ProtectedRoute;
