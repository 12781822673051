import React from 'react';
import { Card, Icon } from '../../../common';
import { getSafeValue } from '../../../utils/get_safe_value';
import DisplayDateTime from '../../../utils/time/display_time';
import { Flex } from 'antd';
import { useNavigate } from 'react-router-dom';
import { PATIENT_EDIT_PATH } from '../../../constants/admin-paths';
import Permission from '../../permission/Permission';
import { PERMISSIONS_STRUCT } from '../../../constants/permissions';

const LeadPatient = ({ patientDetail = {} }) => {
  const navigate = useNavigate();

  return (
    <Card>
      <Flex justify="space-between" align="center">
        <div className="card-title">Patient</div>
        <Permission models={PERMISSIONS_STRUCT.PATIENT.NAME} actions={PERMISSIONS_STRUCT.PATIENT.ACTIONS.UPDATE}>
          {patientDetail?.id && (
            <Icon
              icon="FaEdit"
              className="clickable"
              color="#EB7575"
              onClick={() => {
                navigate(PATIENT_EDIT_PATH.replace(':id', patientDetail.id), {
                  replace: false,
                });
              }}
            />
          )}
        </Permission>
      </Flex>
      <div className="row mt-3">
        <div className="col-md-6 col-12">
          <div className="mb-2 ms-2 text-muted">First Name</div>
          <div className="mb-3 ms-3">{patientDetail?.first_name}</div>
        </div>
        <div className="col-md-6 col-12">
          <div className="mb-2 ms-2 text-muted">Last Name</div>
          <div className="mb-3 ms-3">{patientDetail?.last_name}</div>
        </div>
        <div className="col-md-6 col-12">
          <div className="mb-2 ms-2 text-muted">Email</div>
          <div className="mb-3 ms-3">{patientDetail?.email}</div>
        </div>
        {patientDetail?.secondary_email && (
          <div className="col-md-6 col-12">
            <div className="mb-2 ms-2 text-muted">Secondary Email</div>
            <div className="mb-3 ms-3">{getSafeValue(patientDetail?.secondary_email)}</div>
          </div>
        )}
        <div className="col-md-6 col-12">
          <div className="mb-2 ms-2 text-muted">Phone</div>
          <div className="mb-3 ms-3">{patientDetail.phone_number}</div>
        </div>
        {patientDetail?.secondary_phone_number && (
          <div className="col-md-6 col-12">
            <div className="mb-2 ms-2 text-muted">Secondary Phone</div>
            <div className="mb-3 ms-3">{getSafeValue(patientDetail?.secondary_phone_number)}</div>
          </div>
        )}
        <div className="col-md-6 col-12">
          <div className="mb-2 ms-2 text-muted">Gender</div>
          <div className="mb-3 ms-3">{getSafeValue(patientDetail?.gender_label)}</div>
        </div>
        <div className="col-md-6 col-12">
          <div className="mb-2 ms-2 text-muted">DOB</div>
          <div className="mb-3 ms-3">{getSafeValue(patientDetail?.date_of_birth)}</div>
        </div>
        {patientDetail?.samcart_customer_id && (
          <div className="col-md-6 col-12">
            <div className="mb-2 ms-2 text-muted">Samcart Customer Id</div>
            <div className="mb-3 ms-3">{getSafeValue(patientDetail?.samcart_customer_id)}</div>
          </div>
        )}
        {patientDetail?.mdi_patient_id && (
          <div className="col-md-6 col-12">
            <div className="mb-2 ms-2 text-muted">Mdi Patient Id</div>
            <div className="mb-3 ms-3">{getSafeValue(patientDetail?.mdi_patient_id)}</div>
          </div>
        )}
        <div className="col-md-6 col-12">
          <div className="mb-2 ms-2 text-muted">Updated at</div>
          <div className="mb-3 ms-3">{DisplayDateTime(patientDetail?.updated_at)}</div>
        </div>
      </div>
    </Card>
  );
};

export default LeadPatient;
