import React from 'react';
// import PropTypes from 'prop-types';
import './status_button.css';

const StatusButton = ({ status }) => {
  return (
    <button className="status-button" style={{ color: status?.color, backgroundColor: status?.bg_color }}>
      {status?.name}
    </button>
  );
};

// StatusButton.propTypes = {
//   name: PropTypes.string.isRequired,
//   color: PropTypes.string,
//   bgColor: PropTypes.string,
// };

// StatusButton.defaultProps = {
//   color: '#000000',
//   bgColor: '#000000',
// };

export default StatusButton;
