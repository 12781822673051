import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Group, Input, Select } from '../../../common';
import { formatOptionsForSelect } from '../../../utils/filters-util';
import { createUserApi, updateUserApi } from '../../../api/users-api';
import toast from 'react-hot-toast';
import { getErrorString } from '../../../utils';
import { Tooltip } from 'react-tooltip';

const UserInfoModalBody = forwardRef(
  ({ userId = '', userData = {}, userRoles = [], setIsLoading = () => {}, onSuccess = () => {} }, ref) => {
    const formikRef = useRef();
    const getValidationSchema = () =>
      yup.object().shape({
        name: yup.string().required('Name is required'),
        email: yup.string().required('Email is required').email('Valid Email is required'),
        password: !userId
          ? yup
              .string()
              .required('Password is required')
              .test('length', 'Minimum 6 characters required', (value) => value?.length > 5)
          : yup.string().test('length', 'Minimum 6 characters required', (value) => (value ? value?.length > 5 : true)),
        roles: yup
          .array()
          .nullable()
          .test('roles', 'Role is required', function (value) {
            return !!this?.parent?.roles?.length > 0;
          }),
      });

    const getInitialValues = () => {
      return {
        name: userData?.name || '',
        email: userData?.email || '',
        password: userData?.password || '',
        roles: userData?.roles || [],
      };
    };

    const handleFormSubmit = async (values) => {
      setIsLoading(true);
      try {
        const payload = {
          user: {
            name: values?.name ?? '',
            email: values?.email ?? '',
            role_ids: values?.roles?.map((item) => item?.id) ?? [],
            ...(values?.password ? { password: values?.password ?? '' } : {}),
          },
        };
        const res = !userId ? await createUserApi(payload) : await updateUserApi(payload, userId);
        if (res && res?.status) {
          toast.success('User Saved Successfully');
          onSuccess();
        }
      } catch (error) {
        toast.error(getErrorString(error));
      } finally {
        setIsLoading(false);
      }
    };

    useImperativeHandle(ref, () => ({
      submitForm() {
        formikRef?.current?.submitForm();
      },
    }));

    return (
      <Formik
        innerRef={formikRef}
        initialValues={getInitialValues()}
        validationSchema={getValidationSchema()}
        onSubmit={handleFormSubmit}
      >
        {(formikProps) => {
          const { values, handleSubmit, handleChange, handleBlur, setFieldValue, touched, errors } = formikProps;
          return (
            <form>
              <Group template={'repeat(2, 1fr)'}>
                <Input
                  placeholder="Enter Name"
                  name="name"
                  label="Name"
                  value={values?.name}
                  errorMsg={errors?.name && touched?.name && errors?.name}
                  onChange={handleChange}
                  onPressEnter={handleSubmit}
                  required
                />
                <Input
                  placeholder="Enter Email"
                  name="email"
                  label="Email"
                  value={values?.email}
                  errorMsg={errors?.email && touched?.email && errors?.email}
                  onChange={handleChange}
                  onPressEnter={handleSubmit}
                  required
                  disabled={!!userId}
                />
                <Input
                  placeholder={!userId ? 'Enter password' : 'Leave blank to keep current password'}
                  name="password"
                  label={!userId ? 'Password' : 'New Password'}
                  value={values?.password}
                  errorMsg={errors?.password && touched?.password && errors?.password}
                  onChange={handleChange}
                  onPressEnter={handleSubmit}
                  required={!userId}
                  data-tooltip-id={!!userId ? 'edit-password' : ''}
                />
                <Tooltip
                  id="edit-password"
                  content={
                    "Leave this field blank if you don't want to update the password. Enter a new password only if you wish to change it"
                  }
                  style={{ zIndex: 99 }}
                />
                <Select
                  label={'Roles'}
                  placeholder={'Select Roles'}
                  value={formatOptionsForSelect({ list: values.roles })}
                  options={formatOptionsForSelect({ list: userRoles })}
                  onChange={(options) => {
                    setFieldValue('roles', [...options]);
                  }}
                  onBlur={handleBlur('roles')}
                  errorMsg={errors?.roles && touched?.roles && errors?.roles}
                  mode={'multiple'}
                  required
                />
              </Group>
            </form>
          );
        }}
      </Formik>
    );
  },
);

export default UserInfoModalBody;
