export const isEmptyObject = (obj) =>
  obj && Object.keys(obj).length === 0 && Object.getPrototypeOf(obj) === Object.prototype;

export const isEmptyArray = (arr) => arr && Array.isArray(arr) && arr?.length === 0;

export const getErrorString = (err) => {
  const errorData = err?.response?.data;
  if (!errorData) return err?.response?.statusText || err?.message;
  const errorExtracted = errorData?.error || errorData?.message || errorData;

  if (typeof errorExtracted === 'string') return errorExtracted;
  if (errorExtracted?.length) return errorExtracted?.join(', ');
};

export const isFieldRestricted = (field) => {
  if (field) {
    return field === 'Restricted';
  } else {
    return false;
  }
};
