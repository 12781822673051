import APIController from '../core-api-controller';

//Listing's Api

export const fetchUsersApi = (paramsObj = {}, paramsStr = '') => {
  const path = `${APIController.endpointApi}/users${paramsStr ? paramsStr : ''}`;
  return APIController.getApiCallback(path, paramsObj);
};

export const createUserApi = (data = {}) => {
  const path = `${APIController.endpointApi}/users`;
  return APIController.postCallback(path, data);
};

export const updateUserApi = (data = {}, userId = '') => {
  const path = `${APIController.endpointApi}/users/${userId}`;
  return APIController.putCallback(path, data);
};
