import React, { Fragment } from 'react';
import Input from '../../../common/input/Input';
import { FILTER_TYPES } from '../../../constants/generic-constants';
import { filterFieldFormat, formatOptionsForSelect } from '../../../utils/filters-util';
import { Select } from '../../../common';

const RenderFilters = ({
  item,
  filtersObject = {},
  setFiltersObject = () => {},
  handleFiltersChange = () => {},
  loading = false,
}) => {
  const onSearch = (object = filtersObject, onEnter = false) => {
    setFiltersObject({ ...object });
    !!onEnter && handleFiltersChange({ ...object });
  };

  const handleSelectValueChange = ({ value = [], searchKey = '', mode = 'single', setAsSlug = false }) => {
    filtersObject.page && delete filtersObject.page;
    let filterValue = '';
    if (mode === 'multiple') {
      filterValue = value.map((item) => item?.id)?.join(',');
    } else if (mode === 'single') {
      filterValue = setAsSlug ? value?.slug : value?.id;
    }
    const obj = { ...filtersObject, [searchKey]: `${filterValue}` };
    onSearch(obj, true);
  };

  const getFilterType = () => {
    switch (item.type) {
      case FILTER_TYPES.DROP_DOWN:
        const selectedIds = filtersObject[item.key] ? filtersObject[item.key]?.split(',') : [];
        const selected = item.options.filter((option) => {
          return selectedIds.find((selectedItem) => {
            if (item.setAsSlug ? selectedItem === option.slug : parseInt(selectedItem) === option.id) {
              return option;
            }
          });
        });
        return (
          <Select
            label={item?.label ?? ''}
            placeholder={item?.placeholder ?? ''}
            mode={item.mode}
            value={formatOptionsForSelect({ list: selected })}
            options={formatOptionsForSelect({ list: item.options, setAsSlug: item.setAsSlug })}
            allowClear={true}
            onChange={(value) => {
              handleSelectValueChange({
                value,
                searchKey: item.key,
                mode: item?.mode,
                setAsSlug: item?.setAsSlug ? item?.setAsSlug : false,
              });
            }}
            onClear={() => {
              delete filtersObject[item.key];
              onSearch(filtersObject, true);
            }}
          />
        );
      default:
        return (
          <Input
            value={filterFieldFormat(filtersObject[item.key || ''])}
            label={item?.label ?? ''}
            placeholder={item?.placeholder ?? ''}
            type={item?.inputType ?? 'text'}
            allowClear={true}
            onChange={(e) => {
              filtersObject.page && delete filtersObject.page;
              setFiltersObject({
                ...filtersObject,
                [item.key]: e.target.value,
              });
            }}
            onPressEnter={() => {
              if (item?.toLowerCase) {
                const obj = {
                  ...filtersObject,
                  [item.key]:
                    item?.inputType === 'number' ? filtersObject[item.key] : filtersObject[item.key]?.toLowerCase(),
                };
                onSearch(obj, true);
              } else {
                onSearch(filtersObject, true);
              }
            }}
          />
        );
    }
  };

  return <Fragment>{getFilterType()}</Fragment>;
};

export default RenderFilters;
