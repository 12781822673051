import './not_found.css';
import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/logos/logo_round.png';

export default function NotFound() {
  return (
    <div className="Center_NotFound">
      <img src={logo} className="App-logo" alt="logo" />
      <h1>-404-</h1>
      <h2>Not Found</h2>
      <br />
      <h2>This Path Doesn't Exist.</h2>
      <Link to="/">Go To HomePage</Link>
    </div>
  );
}
