import React from 'react';
import {
  // FaPlusSquare,
  // FaUserPlus,
  // FaUserSlash,
  FaDollarSign,
  FaUsers,
  // FaCalendarTimes,
  // FaCalendarCheck,
  // FaHourglassHalf,
  // FaFileInvoiceDollar,
  // FaStickyNote,
  // FaCloudUploadAlt,
  // FaCartPlus,
  FaUser,
  // FaLock,
  // FaBusinessTime,
  FaUserClock,
  FaBriefcaseMedical,
} from 'react-icons/fa';
import { RiUserStarFill, RiUserSettingsFill } from 'react-icons/ri';
import { PRODUCTS_PATH, USERS_PATH } from '../../constants/admin-paths';
import { SUBSCRIPTIONS_PATH } from '../../constants/admin-paths';
import { PERMISSION_MODELS } from '../../constants/permissions';
// import { MdOutgoingMail } from "react-icons/md";

export const management_sections = [
  {
    title: 'Management',
    items: [
      {
        key: PERMISSION_MODELS.LEAD,
        title: 'Leads',
        path: '/leads',
        icon: <FaUsers />,
      },
      {
        key: PERMISSION_MODELS.PATIENT,
        title: 'Patients',
        path: '/patients',
        icon: <FaUser />,
      },
      {
        key: PERMISSION_MODELS.ORDER,
        title: 'Orders',
        path: '/orders',
        icon: <FaDollarSign />,
      },
      {
        key: PERMISSION_MODELS.CASE,
        title: 'Cases',
        path: '/cases',
        icon: <FaUserClock />,
        roles: ['admin'],
      },
      {
        key: PERMISSION_MODELS.STATUS,
        title: 'Statuses',
        path: '/statuses',
        icon: <FaDollarSign />,
      },
      {
        key: PERMISSION_MODELS.PRODUCT,
        title: 'Products',
        path: PRODUCTS_PATH,
        icon: <FaBriefcaseMedical />,
      },
      {
        key: PERMISSION_MODELS.SUBSCRIPTION,
        title: 'Subscriptions',
        path: SUBSCRIPTIONS_PATH,
        icon: <RiUserStarFill />,
      },
      {
        key: PERMISSION_MODELS.USER,
        title: 'Users',
        path: USERS_PATH,
        icon: <RiUserSettingsFill />,
      },

      // {
      //   title: "Add License",
      //   path: "/Admin/AddLicense",
      //   icon: <FaPlusSquare />,
      //   roles: ["admin"]
      // },
      // {
      //   title: "Licenses",
      //   path: "/Admin/Licenses",
      //   icon: <FaStickyNote />,
      //   roles: ["admin","sales"]
      // },
      // {
      //   title: "Trial Licenses",
      //   path: "/Admin/triallicenses",
      //   icon: <FaUsers />,
      //   roles: ["admin","sales"]
      // },
      // {
      //   title: "Trial Verify Codes",
      //   path: "/Admin/TrialVerificationCodes",
      //   icon: <FaUserClock />,
      //   roles: ["admin","sales"]
      // },
      // {
      //   title: "License Verify Codes",
      //   path: "/Admin/LicenseVerificationCodes",
      //   icon: <FaUserClock />,
      //   roles: ["admin","sales"]
      // },
      // {
      //   title: "Active Users",
      //   path: "/Admin/ActiveTrials",
      //   icon: <FaUsers />,
      //   roles: ["admin","sales"]
      // },
      // {
      //   title: "Expired Trials",
      //   path: "/Admin/ExpiredTrials",
      //   icon: <FaUserSlash />,
      //   roles: ["admin","sales"]
      // },
      // {
      //   title: "Active Licenses",
      //   path: "/Admin/ActiveLicenses",
      //   icon: <FaCalendarCheck />,
      //   // icon: <FaIcons.FaEnvelopeOpenText />,
      //   roles: ["admin","sales"]
      // },
      // {
      //   title: "Expired Licenses",
      //   path: "/Admin/ExpiredLicenses",
      //   icon: <FaCalendarTimes />,
      //   roles: ["admin","sales"]
      // },
    ],
  },
  // {
  //   title: "User Management",
  //   items: [
  //     {
  //       title: "Add User",
  //       path: "/Admin/AddUser",
  //       icon: <FaUserPlus />,
  //       roles: ["admin"]
  //     },
  //     {
  //       title: "Users",
  //       path: "/Admin/Users",
  //       icon: <FaUser />,
  //       roles: ["admin","sales"]
  //     },
  //     // {
  // //   title: "Trial Users",
  // //   path: "/Admin/TrialUsers",
  // //   icon: <FaUsers />,
  // //   roles: ["admin","sales"]
  // // },
  //   ]
  // },
  // {
  //   title: "Payment management",
  //   items: [
  //     {
  //       title: "Generated Orders",
  //       path: "/Admin/GeneratedOrders",
  //       icon: <FaBusinessTime />,
  //       roles: ["admin"]
  //     },
  //     {
  //       title: "Completed Orders",
  //       path: "/Admin/CompletedOrders",
  //       icon: <FaDollarSign />,
  //       roles: ["admin"]
  //     },
  //     // {
  //     //     title: "License Invoice",
  //     //     path: "/Admin/LicenseInvoice",
  //     //     icon: <FaDollarSign />,
  //     //     roles: ["admin"]
  //     //   },
  //   ]
  // },
  // {
  //   title: "Marketing",
  //   items: [
  //     {
  //       title: "Send Emails",
  //       path: "/Admin/SendEmail",
  //       icon: <MdOutgoingMail />,
  //       roles: ["admin","sales"]
  //     },
  //     {
  //       title: "Requested Quotes",
  //       path: "/Admin/Quotes",
  //       icon: <FaCartPlus />,
  //       roles: ["admin","sales"]
  //     },
  //   ]
  // },
  // {
  //   title: "products",
  //   items: [
  //     {
  //       title: "Add New Product",
  //       path: "/Admin/AddProduct",
  //       icon: <FaPlusSquare />,
  //       roles: ["admin"]
  //     },
  //     {
  //       title: "Active Products",
  //       path: "/Admin/ActiveProducts",
  //       icon: <FaFileInvoiceDollar />,
  //       roles: ["admin"]
  //     },
  // {
  //   title: "Upload New Version",
  //   path: "/Admin/ProductUpload",
  //   icon: <FaCloudUploadAlt />,
  // },
  // {
  //   title: "Product Release Notes",
  //   path: "/Admin/ProductReleaseNotes",
  //   icon: <FaStickyNote />,
  // },
  // ]
  // },
];
