import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Input } from '../../../common';

const MergeLeadModalBody = forwardRef(({ handleSubmit = () => {} }, ref) => {
  const formikRef = useRef();
  const getValidationSchema = () =>
    yup.object().shape({
      email: yup.string().required('Email is required').email('Valid Email is required'),
    });

  const getInitialValues = () => ({
    email: '',
  });

  useImperativeHandle(ref, () => ({
    submitForm() {
      formikRef?.current?.submitForm();
    },
  }));

  return (
    <Formik
      innerRef={formikRef}
      initialValues={getInitialValues()}
      validationSchema={getValidationSchema()}
      onSubmit={handleSubmit}
    >
      {(formikProps) => {
        const { values, handleSubmit, handleChange, touched, errors } = formikProps;
        return (
          <form>
            <Input
              placeholder="Enter Lead Email"
              name="email"
              label="Email"
              value={values?.email}
              autoFocus={true}
              errorMsg={errors?.email && touched?.email && errors?.email}
              onChange={handleChange}
              onPressEnter={handleSubmit}
            />
          </form>
        );
      }}
    </Formik>
  );
});

export default MergeLeadModalBody;
