import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { getErrorString } from '../../utils';
import { fetchPermissionsApi } from '../../api/permissions-api';
import { PURGE } from 'redux-persist';

const initialState = {
  permissions: {},
  isAdmin: false,
  loading: false,
  error: '',
};

export const fetchPermissions = createAsyncThunk('permissions/data', async () => {
  try {
    const res = await fetchPermissionsApi();
    return res;
  } catch (error) {
    toast.error(getErrorString(error));
    return getErrorString(error);
  }
});

const PermissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(fetchPermissions.pending, (state) => {
        state.loading = true;
        state.error = '';
      })
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        state.permissions = action?.payload?.data || [];
        state.loading = false;
        state.error = '';
      })
      .addCase(fetchPermissions.rejected, (state, action) => {
        state.loading = false;
        state.error = action?.error || action?.payload?.error || '';
      })
      .addCase(PURGE, (state) => {
        state = { ...initialState };
      });
  },
});

export default PermissionsSlice.reducer;
