import { useSelector } from 'react-redux';
import { selectPermissions } from '../../reducers/selectors';

const usePermissions = () => {
  const { permissions } = useSelector(selectPermissions);

  const getModelActionsPermission = (model, action) => {
    if (typeof action === 'string') {
      return permissions?.[model] && permissions?.[model]?.actions?.[action];
    } else {
      let checkAllAction = true;
      action?.forEach((item) => {
        if (!permissions?.[model]?.actions?.[item]) {
          checkAllAction = false;
        }
      });
      return permissions?.[model] && checkAllAction;
    }
  };

  const getPermission = ({ models = '', actions = '' }) => {
    if (permissions?.all) return true;

    if (typeof models === 'string') {
      return !!getModelActionsPermission(models, actions);
    } else {
      let checkAllModels = false;
      models?.forEach((item) => {
        checkAllModels = !!getModelActionsPermission(item, actions);
      });
      return checkAllModels;
    }
  };

  return {
    getPermission,
  };
};

export default usePermissions;
