export const PERMISSION_MODELS = Object.freeze({
  USER: 'user',
  PRODUCT: 'product',
  ORDER: 'order',
  LEAD: 'lead',
  SUBSCRIPTION: 'subscription',
  CASE: 'case',
  PATIENT: 'patient',
  PRESCRIPTION: 'prescription',
  STATUS: 'status',
});

const DEFAULT_PERMISSION_ACTIONS = {
  SHOW: 'show',
  INDEX: 'index',
  CREATE: 'create',
  UPDATE: 'update',
};

export const PERMISSIONS_STRUCT = {
  LEAD: {
    NAME: PERMISSION_MODELS.LEAD,
    ACTIONS: {
      MERGE_LEAD: 'merge_lead',
      SYNC_LEAD: 'sync',
      ...DEFAULT_PERMISSION_ACTIONS,
    },
  },
  PATIENT: {
    NAME: PERMISSION_MODELS.PATIENT,
    ACTIONS: {
      ...DEFAULT_PERMISSION_ACTIONS,
    },
  },
  ORDER: {
    NAME: PERMISSION_MODELS.ORDER,
    ACTIONS: {
      ...DEFAULT_PERMISSION_ACTIONS,
    },
  },
  CASE: {
    NAME: PERMISSION_MODELS.CASE,
    ACTIONS: {
      ...DEFAULT_PERMISSION_ACTIONS,
    },
  },
  STATUS: {
    NAME: PERMISSION_MODELS.STATUS,
    ACTIONS: {
      ...DEFAULT_PERMISSION_ACTIONS,
    },
  },
  PRODUCT: {
    NAME: PERMISSION_MODELS.PRODUCT,
    ACTIONS: {
      SYNC_PRODUCT: 'sync',
      ...DEFAULT_PERMISSION_ACTIONS,
    },
  },
  SUBSCRIPTION: {
    NAME: PERMISSION_MODELS.SUBSCRIPTION,
    ACTIONS: {
      ...DEFAULT_PERMISSION_ACTIONS,
    },
  },
  USER: {
    NAME: PERMISSION_MODELS.USER,
    ACTIONS: {
      ...DEFAULT_PERMISSION_ACTIONS,
    },
  },
};
