import React from 'react';
import cx from 'clsx';
import { ColorPicker as AntdColorPicker } from 'antd';
import Label from '../label/Label';
import ErrorMessage from '../error-message/ErrorMessage';
import styles from './ColorPicker.module.scss';

const ColorPicker = ({
  containerClassName = '',
  defaultValue = '',
  value = '',
  placeholder = '',
  label = '',
  labelClassName = '',
  required = false,
  errorMsg = '',
  disabled = false,
  onChange = () => {},
  size = 'medium',
}) => {
  return (
    <div className={cx('d-flex flex-column', containerClassName)}>
      {label && (
        <Label className={labelClassName} required={required}>
          {label}
        </Label>
      )}
      <AntdColorPicker
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        onChange={onChange}
        showText={(color) => (
          <span>
            {placeholder} {color.toHexString()}
          </span>
        )}
        size={size}
        allowClear
      />
      {errorMsg && <ErrorMessage errorMessage={errorMsg} />}
    </div>
  );
};

export default ColorPicker;
