import { REQUEST_TYPES } from '../constants/generic-constants';
import NetworkManager from '../services/network-services/NetworkServices';

export default class APIController {
  static baseUrl = process.env.REACT_APP_BASE_URL;
  static endpointApi = `${APIController.baseUrl}/api`;
  static getError(e) {
    return e.errors
      ? e
      : {
          response: e
            ? e?.error
              ? e?.error?.response
                ? e?.error?.response
                : e?.error?.message
              : e?.response
                ? e?.response
                : e?.message
            : 'Unknown Error',
        };
  }
  static async getApiCallback(path = '', params = {}, signal = null) {
    try {
      const res = await NetworkManager.performRequest({
        url: path,
        params: params,
        type: REQUEST_TYPES.GET,
        signal: signal,
      });
      return res.data;
    } catch (e) {
      const formattedError = APIController.getError(e);
      throw formattedError;
    }
  }
  static async postCallback(path = '', object = {}, responseOnly = false) {
    try {
      const res = await NetworkManager.performRequest({ url: path, data: object, type: REQUEST_TYPES.POST });
      return responseOnly ? res : res.data;
    } catch (e) {
      const formattedError = APIController.getError(e);
      throw formattedError;
    }
  }
  static async putCallback(path = '', object = {}, errorKey = null) {
    try {
      const res = await NetworkManager.performRequest({ url: path, data: object, type: REQUEST_TYPES.PUT });
      return res.data;
    } catch (e) {
      if (errorKey && e[errorKey]) return { multi_errors: e[errorKey] };
      return APIController.getError(e);
    }
  }
  static async deleteCallback(path = '', object = {}, responseOnly) {
    try {
      const res = await NetworkManager.performRequest({ url: path, data: object, type: REQUEST_TYPES.DELETE });
      return responseOnly ? res : res.data;
    } catch (e) {
      return APIController.getError(e);
    }
  }
}
