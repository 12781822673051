import React from 'react';
import cx from 'clsx';
import AntIcon from '@ant-design/icons';
import styles from '../Icon.module.scss';
import * as ReactIcons from '../../all-icons/react-icons';
import * as CustomIcons from '../../all-icons/custom-icons';

const RenderIcon = ({ icon, iconSize, color, className, ...rest }) => {
  const Component = CustomIcons[icon] || ReactIcons[icon];

  return (
    <AntIcon
      className={cx(styles.icon, className)}
      color={color}
      style={{
        '--icon-size': iconSize,
        '--icon-color': color,
        '--icon-stroke': 'currentColor',
        ...rest.iconStyles,
      }}
      {...(!!Component ? { component: () => <Component size={iconSize} {...rest} /> } : {})}
    />
  );
};

export default RenderIcon;
