import React from 'react';
import cx from 'clsx';
import { Button as AntdButton } from 'antd';
import styles from './Button.module.scss';
import Icon from '../icon/Icon';

const Button = ({
  shape,
  size,
  type,
  icon,
  iconPosition,
  iconSize,
  iconColor,
  iconHasBackground,
  iconBgSize,
  iconBgColor,
  onClick,
  children,
  text,
  style,
  className,
  bordered,
  htmlType,
  loading = false,
  wide,
  disabled = false,
  ...rest
}) => {
  return (
    <AntdButton
      shape={shape}
      size={size}
      type={type}
      {...(icon
        ? {
            icon: (
              <Icon
                icon={icon}
                iconSize={iconSize}
                color={iconColor}
                hasBackground={iconHasBackground}
                bgSize={iconBgSize}
                bgColor={iconBgColor}
              />
            ),
          }
        : {})}
      iconPosition={iconPosition}
      onClick={onClick}
      className={cx(className, styles.btn, bordered && 'ant-btn-bordered', wide && styles.btnWide)}
      style={style}
      htmlType={htmlType}
      loading={loading}
      disabled={disabled}
      {...rest}
    >
      {children || text}
    </AntdButton>
  );
};

export default Button;
