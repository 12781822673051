export const REQUEST_TYPES = {
  POST: 'post',
  PUT: 'put',
  PATCH: 'patch',
  GET: 'get',
  DELETE: 'delete',
};

export const SIDE_MENU_WIDTH = {
  open: 230,
  close: 72,
};

export const FILTER_TYPES = {
  DROP_DOWN: 'dropdown',
};

export const SYNC_TYPES = Object.freeze({
  MDI: 'mdi',
  SAMCART: 'samcart',
});

export const USERS_MODAL_TYPES = Object.freeze({
  ADD_USER: 'add_user',
  EDIT_USER: 'edit_user',
});
