import React from 'react';
import { FaDollarSign, FaUsers, FaUser, FaUserClock, FaBriefcaseMedical, FaHome } from 'react-icons/fa';
import { RiUserStarFill, RiUserSettingsFill } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import {
  LEADS_PATH,
  PRODUCTS_PATH,
  USERS_PATH,
  SUBSCRIPTIONS_PATH,
  PATIENTS_PATH,
  ORDERS_PATH,
  CASES_PATH,
  STATUSES_PATH,
} from '../../../constants/admin-paths';
import { PERMISSION_MODELS } from '../../../constants/permissions';
import { DashboardIcon } from '../../../common/all-icons/custom-icons';
import { Icon } from '../../../common';

export const menu_items = [
  {
    key: '/',
    label: <Link to={'/'}>Dashboard</Link>,
    icon: <Icon icon="DashboardIcon" />,
  },
  {
    permkey: PERMISSION_MODELS.LEAD,
    key: LEADS_PATH,
    label: <Link to={LEADS_PATH}>Leads</Link>,
    icon: <Icon icon="LeadsIcon" />,
  },
  {
    permkey: PERMISSION_MODELS.PATIENT,
    key: PATIENTS_PATH,
    label: <Link to={PATIENTS_PATH}>Patients</Link>,
    icon: <Icon icon="PatientIcon" />,
  },
  {
    permkey: PERMISSION_MODELS.ORDER,
    key: ORDERS_PATH,
    label: <Link to={ORDERS_PATH}>Orders</Link>,
    icon: <Icon icon="OrdersIcon" />,
  },
  {
    permkey: PERMISSION_MODELS.CASE,
    key: CASES_PATH,
    label: <Link to={CASES_PATH}>Cases</Link>,
    icon: <Icon icon="CasesIcon" />,
  },
  {
    permkey: PERMISSION_MODELS.STATUS,
    key: STATUSES_PATH,
    label: <Link to={STATUSES_PATH}>Statuses</Link>,
    icon: <Icon icon="StatusesIcon" />,
  },
  {
    permkey: PERMISSION_MODELS.PRODUCT,
    key: PRODUCTS_PATH,
    label: <Link to={PRODUCTS_PATH}>Products</Link>,
    icon: <Icon icon="ProductsIcon" />,
  },
  {
    permkey: PERMISSION_MODELS.SUBSCRIPTION,
    key: SUBSCRIPTIONS_PATH,
    label: <Link to={SUBSCRIPTIONS_PATH}>Subscriptions</Link>,
    icon: <Icon icon="SubscriptionsIcon" />,
  },
  {
    permkey: PERMISSION_MODELS.USER,
    key: USERS_PATH,
    label: <Link to={USERS_PATH}>Users</Link>,
    icon: <RiUserSettingsFill />,
  },
];
