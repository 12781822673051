import React, { Fragment, Suspense, useEffect } from 'react';
import AdminDashboardLayout from '../admin_dashboard/admin_dashboard_layout';
import { Outlet } from 'react-router-dom';
import Loading from 'react-fullscreen-loading';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPermissions } from '../../reducers/permissions-slice';
import { selectPermissions } from '../../reducers/selectors';
import { isEmptyObject } from '../../utils';

const Layout = ({ user }) => {
  const dispatch = useDispatch();
  const { permissions, loading } = useSelector(selectPermissions);

  useEffect(() => {
    user && isEmptyObject(permissions) && dispatch(fetchPermissions());
  }, []);

  if (loading) return <Loading loading={true} background="#fff" loaderColor="#883484" />;

  return (
    <Fragment>
      <AdminDashboardLayout>
        <Suspense fallback={<Loading loading={true} background="#fff" loaderColor="#883484" />}>
          <Outlet />
        </Suspense>
      </AdminDashboardLayout>
    </Fragment>
  );
};

export default Layout;
