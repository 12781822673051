import React, { Fragment, useState } from 'react';
import { Button } from '../../../common';
import DisplayDateTime from '../../../utils/time/display_time';
import StatusButton from '../../../utils/status/status_button';
import { Card, Icon, Modal, Select } from '../../../common';
import { Flex } from 'antd';
import { formatOptionsForSelect } from '../../../utils/filters-util';
import MergeLeadModal from '../../modals/merge-lead-modal/MergeLeadModal';
import Permission from '../../permission/Permission';
import { PERMISSIONS_STRUCT } from '../../../constants/permissions';

const LeadDetailHeader = ({
  leadDetail = {},
  leadStatuses = [],
  currentStatusId = '',
  handleStatusChange = () => {},
  handleResyncData = () => {},
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const infoItem = ({
    title,
    subTitile = '-',
    iconOptions = { color: '', size: '', bgColor: '', bgSize: '', iconName: '', hasBackground: true },
    truncated = false,
  }) => {
    return (
      <div className={`info-block d-flex ${truncated ? 'info-block-truncated' : ''}`.trim()}>
        <Icon
          icon={iconOptions.iconName}
          color={iconOptions.color}
          bgSize={iconOptions.bgSize}
          hasBackground={iconOptions.hasBackground}
          {...iconOptions}
        />
        <div className="info-block-body">
          <div className="info-block-title">{title}</div>
          <div className="info-block-desc" title={truncated === true ? subTitile : ''}>
            {subTitile || '-'}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <div className="row mx-2">
        <Card className="mt-4">
          <Flex vertical>
            <Flex justify="space-between" style={{ marginBottom: '5px' }}>
              <div>
                <StatusButton status={leadDetail?.status} />
              </div>
              <Flex gap={10}>
                <Permission models={PERMISSIONS_STRUCT.LEAD.NAME} actions={PERMISSIONS_STRUCT.LEAD.ACTIONS.MERGE_LEAD}>
                  <MergeLeadModal leadId={leadDetail?.id || ''} />
                </Permission>
                <Permission models={PERMISSIONS_STRUCT.LEAD.NAME} actions={PERMISSIONS_STRUCT.LEAD.ACTIONS.SYNC_LEAD}>
                  <Button text="Re-Sync" size="medium" type="primary" onClick={handleResyncData} />
                </Permission>
                <Permission models={PERMISSIONS_STRUCT.LEAD.NAME} actions={PERMISSIONS_STRUCT.LEAD.ACTIONS.UPDATE}>
                  <Select
                    placeholder="Change Lead Status"
                    allowClear
                    onChange={handleStatusChange}
                    options={formatOptionsForSelect({ list: leadStatuses })}
                    selectSize="medium"
                  />
                </Permission>
              </Flex>
            </Flex>
            <div className={`d-flex flex-wrap align-items-center mt-2`.trim()} style={{ gap: '6px' }}>
              {infoItem({
                title: 'Id',
                subTitile: leadDetail?.id,
                iconOptions: { iconName: 'RiSuitcaseFill', color: '#ffa564', bgSize: '30px', hasBackground: true },
              })}
              {infoItem({
                title: 'State',
                subTitile: leadDetail?.patient?.address?.state,
                iconOptions: { iconName: 'FaMapLocationDot', color: '#EB7575', bgSize: '30px', hasBackground: true },
              })}
              {infoItem({
                title: 'Created at',
                subTitile: DisplayDateTime(leadDetail?.created_at),
                iconOptions: { iconName: 'FaCalendarDays', color: '#559362', bgSize: '30px', hasBackground: true },
              })}
              {infoItem({
                title: 'Updated at',
                subTitile: DisplayDateTime(leadDetail?.updated_at),
                iconOptions: { iconName: 'FaCalendarDays', color: '#8DBCCF', bgSize: '30px', hasBackground: true },
              })}
              {infoItem({
                title: 'MDI Synced at',
                subTitile: DisplayDateTime(leadDetail?.last_mdi_synced_at),
                iconOptions: { iconName: 'FaCalendarDays', color: '#96633C', bgSize: '30px', hasBackground: true },
              })}
              {infoItem({
                title: 'Samcart Synced at',
                subTitile: DisplayDateTime(leadDetail?.last_samcart_synced_at),
                iconOptions: { iconName: 'FaCalendarDays', color: '#96633C', bgSize: '30px', hasBackground: true },
              })}
            </div>
          </Flex>
        </Card>
      </div>
    </Fragment>
  );
};

export default LeadDetailHeader;
